// Import Swiper React components
import * as React from "react";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import TeamCard from "../TeamCard/TeamCard";
import { teamData } from "./team-data";

export default () => {
  return (
    <Swiper
      loop={true}
      spaceBetween={30}
      breakpoints={{
        576: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 2,
        },
      }}
    >
      {teamData &&
        teamData.map((data, index) => {
          return (
            <SwiperSlide key={index}>
              <TeamCard
                name={data.name}
                imgUrl={data.image}
                designation={data.designation}
                facebook={data.facebook}
              />
            </SwiperSlide>
          );
        })}
    </Swiper>
  );
};
