import React from "react";

const SplitText = ({ text }) => {
  return (
    <>
      {text.split("").map((char, index) => {
        let style = { "animationDelay": 0.5 + index / 10 + "s" };
        return (
          <span style={style} key={index}>
            {char}
          </span>
        );
      })}
    </>
  );
};

export default SplitText;
