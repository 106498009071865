// Import Swiper React components
import * as React from "react";

import { Swiper, SwiperSlide } from "swiper/react";

import { EffectFade, Autoplay, Navigation } from "swiper";
import { motion } from "framer-motion";

// Import Swiper styles
import "swiper/css/effect-fade";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";

export default ({ slideData }) => {
  return (
    <Swiper
      modules={[Navigation, Autoplay, EffectFade]}
      effect="fade"
      loop={true}
      speed={4000}
      autoplay={{
        disableOnInteraction: false,
      }}
      className="h-screen"
      preloadImages={false}
    >
      {slideData &&
        slideData.map((src, index) => {
          return (
            <SwiperSlide key={index}>
              {({ isActive }) => (
                <motion.div
                  initial={{ opacity: 0, scale: 1 }}
                  animate={{
                    opacity: isActive ? 1 : 0,
                    scale: isActive ? 1 : [1, 1.1, 1.2, 1.3],
                  }}
                  transition={{ duration: 4 }}
                >
                  <img
                    src={src}
                    className="object-cover h-screen min-h-screen"
                    style={{
                      display: "block",
                      width: "100%",
                      height: "auto",
                    }}
                  />
                </motion.div>
              )}
            </SwiperSlide>
          );
        })}
    </Swiper>
  );
};
