import React from "react";

function TimerBoxComp(props) {
  return (
    <div className="bg-neutral-600">
      <div className="relative w-20 h-12 md:h-24 md:w-40">
        <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center">
          <span className="text-3xl font-semibold text-white md:text-5xl">
            {props.value}
          </span>
        </div>
      </div>

      <div className="pb-4 text-center">
        <span className="text-lg font-semibold uppercase text-neutral-400">
          {props.label}
        </span>
      </div>
    </div>
  );
}

export default TimerBoxComp;
