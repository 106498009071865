import Slide1 from "../../images/home/main-slider/slide-1.jpg";
import Slide2 from "../../images/home/main-slider/slide-2.jpg";
import Slide3 from "../../images/home/main-slider/slide-3.jpg";
import Slide4 from "../../images/home/main-slider/slide-4.jpg";
import Spiti from "../../images/rides/spiti.jpg";
import Ladakh from "../../images/rides/ladakh.jpg";
import Sikkim from "../../images/rides/sikkim.jpg";
import Zanskar from "../../images/rides/zanskar.jpg";
import Puri from "../../images/rides/puri.jpg";

export const galleryData = [
  Slide1,
  Slide2,
  Slide3,
  Slide4,
  Spiti,
  Ladakh,
  Sikkim,
  Zanskar,
  Puri,
];
