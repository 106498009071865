import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Lazy, FreeMode } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/lazy";

// import required modules
import { galleryData } from "./gallery-data";

const Gallery = () => {
  return (
    <Swiper
      modules={[Lazy]}
      loop={true}
      slidesPerView={2}
      preloadImages={false}
      lazy={true}
      breakpoints={{
        576: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
      }}
    >
      {galleryData &&
        galleryData.map((img, index) => {
          return (
            <SwiperSlide key={index}>
              <img src={img} className="object-cover h-full" />
            </SwiperSlide>
          );
        })}
    </Swiper>
  );
};

export default Gallery;
